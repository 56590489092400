import Atividades from '../../components/Atividades';
import Cabecalho from "../../components/Cabecalho";
import Estrutura from '../../components/Estrutura';
import Footer from '../../components/Footer';
import Header from "../../components/Header";
import Main from '../../components/Main';
import Realizacoes from '../../components/Realizacoes';
import SlidePrincipal from "../../components/SlidePrincipal";
import Sobre from "../../components/Sobre";
import VideoPlayer from '../../components/VideoPlayer';

export default function Home() {
  return (
    <div className='position-relative body'>
      <Header>
        <Cabecalho />
        <SlidePrincipal />
      </Header>
      <Main>
        <Sobre>
          <Atividades />
        </Sobre>
        <Estrutura />
        <VideoPlayer />
        <Realizacoes />
      </Main>
      <Footer />
    </div>
  );
}
