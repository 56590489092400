import { Container, Nav, Navbar } from "react-bootstrap";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import FacebookIcon from "../svg/Facebook";
import logo from "../../img/logo/fazendaSaoJoaquim/logo.webp";
import "./style.css";
export default function Footer() {
  return (
    <div id='contato'>
      <section
        id="Footer"
        className="bg-fazenda position-relative pt-5 d-flex align-items-center"
      >
        <Container className="position-relative">
          <div className="row justify-content-center">
            <div className="col-11 col-lg-6 pb-1 pb-lg-3 border-footer">
              <div className="text-center text-white">
                <p className="mb-0 pb-0">
                  Acompanhe a FAZENDA SÃO JOAQUIM nas mídias sociais
                </p>
                <div>
                  <Navbar className="pb-0">
                    <Nav className="mx-auto">
                      <Nav.Link href="#" className="d-flex">
                        <FacebookIcon />
                      </Nav.Link>
                      <Nav.Link href="#" className="d-flex">
                        <BsInstagram />
                      </Nav.Link>
                    </Nav>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="text-center">
                <div className="py-4">
                  <figure className="m-0">
                    <img src={logo} alt="Fazenda São Joaquim" width={150} />
                  </figure>
                </div>
                <div className="card bg-transparent border-0 p-0">
                  <div className="info text-white card-body">
                    <p>Rod. Mal. Rondon, 830 - Oliveira, Itu/SP</p>
                    <p>CEP 13312-000</p>
                    <p>
                      Telefone: 11 00000-0000 | <BsWhatsapp /> 11 0000-0000
                    </p>
                    <p>reservas@fazendasãojoaquim.com.br</p>
                    <div className="py-2">
                      <p className="m-0">Fazenda São Joaquim</p>
                      <p className="m-0">00.000.000/0000-00</p>
                    </div>
                  </div>
                  <div className="card-footer p-0 border-0 bg-transparent text-center text-white">
                    <p className="m-0">Todos os direitos reservados.</p>
                    <p>
                      Todos os direitos reservados. Política de Privacidade |
                      Política de Cookies
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
