import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./style.css";
import {Autoplay, EffectFade, Navigation } from "swiper";
import carne from './img/carne.webp';
import cama from './img/cama.webp';
import dog from './img/dog.webp';
import hotel_414 from './img/hotel-414.webp';
import hotel_768 from './img/hotel-768.webp';
import hotel_1024 from './img/hotel-1024.webp';
import hotel_1280 from './img/hotel-1280.webp';
import hotel from './img/hotel.webp'
import cavalo from './img/cavalo.webp';
import { Button } from 'react-bootstrap';
export default function SlidePrincipal() {
  return (
    <div id='slidePrincipal' className='position-relative'>
      <Swiper
        slidesPerView={1}
        effect={"fade"}
        spaceBetween={0}
        loop={true}
        navigation={true}
        modules={[Navigation, Autoplay, EffectFade]}
        className="mySwiper"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <img
            src={carne}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={cama}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={cavalo}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={dog}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={hotel_414}
            srcset={`${hotel_414} 414w,${hotel_768} 768w,${hotel_1024} 1024w,${hotel_1280} 1280w,${hotel} 1366w`}
            alt="Acomodações do hotel"
          />
        </SwiperSlide>
      </Swiper>
      <div className='botao_reserve_agora d-flex justify-content-center align-items-center position-absolute w-100'>
        <a href='#Reserve'><Button variant="light" className='btn-white fw-bold'>RESERVE AGORA</Button></a>
      </div>
      <div className='py-0 py-lg-3 bg-pastel borda-SlidePrincial'></div>
    </div>
  );
}
