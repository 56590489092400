import React, { useState } from "react";
import "./style.css";
import { Card, Button, Container } from "react-bootstrap";
import capela from "./img/CAPELA.webp";
import coqueteis from "./img/COQUETEIS.webp";
import ensaiosFotograficos from "./img/ENSAIOSFOTOGRAFICOS.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/lazy";
import { Pagination, Navigation, Lazy } from "swiper";
import Fade from "react-reveal/Fade";
import { Modal } from "react-bootstrap";

export default function Atividades() {
  const arrTeste = ["/static/media/g (493).7f1f831488bb725ea10d.jpg",
  "/static/media/g (494).8c0a3769010a657bccef.jpg",
  "/static/media/g (495).0fe0ccd33d93929b215a.jpg",
  "/static/media/g (496).23ce40039fc90c1c7804.jpg",
  "/static/media/g (497).fa704383e61dbd967049.jpg",
  "/static/media/g (498).743b68e7047ab6fcb45e.jpg",
  "/static/media/g (499).c9fb7da37df0c4ebf795.jpg",
  "/static/media/g (523).e7f3aa196524f0419b2e.jpg",
  "/static/media/g (524).3828f47123c067d9e7a3.jpg",
  "/static/media/g (525).5b0971658dc261d3edf2.jpg",
  "/static/media/g (526).2f3d4436dcaca832f31e.jpg",
  "/static/media/g (527).760b0c1ef502924a262b.jpg",
  "/static/media/g (528).337328439eb2a8f954a8.jpg",
  "/static/media/g (529).44fd55b7f740aabb8b61.jpg",
  "/static/media/g (530).ae5e5cf1efb4be83c254.jpg",
  "/static/media/g (531).48675cbf631af159286a.jpg",
  "/static/media/g (532).9ad76cc40a2987957af6.jpg",
  "/static/media/g (533).a3899b2d97370c0cabab.jpg",
  "/static/media/g (534).d20f074bba1f3c37cb88.jpg",
  "/static/media/g (535).b248a3e14a13f80e92e4.jpg",
  "/static/media/g (536).b0dffa3ffea9734da36c.jpg",
  "/static/media/g (537).2878994fcb59ceddc6ab.jpg",
  "/static/media/g (634).a9f50ce711cde4254a98.jpg",
  "/static/media/g (635).a7b0f26f6882a8dcce24.jpg"
];


  const arr = [
    {
      imagem: capela,
      texto: "CAPELA PARA CASAMENTOS",
      imagens: [capela, coqueteis, ensaiosFotograficos],
    },
    {
      imagem: coqueteis,
      texto: "COQUETÉIS - MINI WEDDING",
      imagens: [capela, coqueteis, ensaiosFotograficos],
    },
    {
      imagem: ensaiosFotograficos,
      texto: "ENSAIOS FOTOGRÁFICOS",
      imagens: [capela, coqueteis, ensaiosFotograficos],
    },
  ];
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint, texto) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <Container>
      <div id="atividades" className="position-relative">
        <Fade bottom distance="30px">
          <div className="slide_atividades position-relative">
            <Swiper
              style={{
                "--swiper-navigation-color": "#5a3722",
                "--swiper-pagination-color": "#5a3722",
              }}
              slidesPerView={3}
              spaceBetween={0}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {arr.map(function (element, index) {
                return (
                  <SwiperSlide key={index}>
                    <Card className="mx-auto">
                      <Card.Img variant="top" src={element["imagem"]} />
                      <Card.Body>
                        <Card.Title className="fontAbhaya">
                          {element["texto"]}
                        </Card.Title>
                        <div className="pt-3">
                            <Button
                              onClick={() => handleShow(true, element['texto'])}
                              className="btn btn-fazenda w-75"
                            >
                              VER ESPAÇO
                            </Button>

                          <Modal
                            show={show}
                            fullscreen={fullscreen}
                            onHide={() => setShow(false)}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{element["texto"]}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="card-slide row justify-content-center">
                                <div className="col-12 col-xl-6">
                                  <Swiper
                                    style={{
                                      "--swiper-navigation-color": "#fff",
                                      "--swiper-pagination-color": "#fff",
                                    }}
                                    lazy={true}
                                    pagination={{
                                      clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Lazy, Pagination, Navigation]}
                                    className="slideCard"
                                  >
                                    {arrTeste.map((e, i) => (
                                      <SwiperSlide key={i}>
                                        <img
                                          data-src={e}
                                          alt=""
                                          className="swiper-lazy"
                                        />
                                        <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </Card.Body>
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Fade>
      </div>

      <></>
    </Container>
  );
}
