import { Card, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";
import { Navigation } from "swiper";

export default function Realizacoes() {
  const mobile = (x) => {
    return window.screen.width > x ? true : false;
  };

  const handleResize = () => {
    window.location.reload(false)
  }
  
  window.addEventListener('orientationchange', handleResize)

  const arr = [
    {
      texto:
        "Já chegando na Fazenda você se encanta com a beleza, o capricho nos detalhes, afluidez na conexão com a natureza, o que se confirma em seguida, na recepção,ambientes e demais espaços. É impressionante como cada detalhe faz a diferença. É um local em que se quer ficar, e não apenas passar por lá! O staff é sensacional, sempre muito atenciosos e disponíveis para orientar, ajudar e acolher.        Fizemos um grande coquetel inesquecível, espero voltar logo.",
      nome: "JULIA",
      local: "ITU/SP",
    },
    {
      texto:
        "Espaço lindo e aconchegante, todos os meus convidados elogiaram muito, o espaço dispõe de muitos cenários para fotos e é bem amplo, excelente opção para quem quer fotos diferenciadas para um casamento no campo, recomendo. Não tenho palavras para agradecer pelo serviço prestado. Ótimos profissionais, com toda a certeza o melhor e mais lindo espaço da região. Fizeram o meu grande dia ser inesquecível.",
      nome: "FERNANDA",
      local: "INDAIATUBA/SP",
    },

    {
      texto:
        "Tudo maravilhoso… \n Meu marido e eu estamos encantados pela maneira como fomos tratados e como a festa foi, melhor que imaginávamos. Os funcionários são bem preparados e servem muito bem aos convidados, faz um mês que casamos e ainda estamos recebendo elogios pela festa! Obrigado por tornar esse dia especial em um momento inesquecível!",
      nome: "CAMILA E JULIANO",
      local: "SOROCABA/SP",
    },
  ];
  return (
    <>
      <div className="row mb-5">
        <div className="col-10 col-sm-8 col-lg-6 col-xxl-4 flex-column d-flex justify-content-center text-center mx-auto texto texto-realizacoes">
          <h1 className="fontAbhaya cor-fazenda fw-bold">Realizações</h1>
        </div>
      </div>
      <Container id="realizacoes" className="my-5">
        <Swiper
          style={{
            "--swiper-navigation-color": "#5a3722",
            "--swiper-pagination-color": "#5a3722",
          }}
          loop
          watchOverflow
          observer={true}
          loopedSlides={50}
          spaceBetween={window.screen.width > 820 ? -450 : 0}
          grabCursor={mobile(820)}
          centeredSlides={mobile(820)}
          slidesPerView={window.screen.width > 820 ? 3 : 1}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            1024: {
              slidesPerView: 2,
              spaceBetween: -330,
            },
            1280: {
              slidesPerView: 2,
              spaceBetween: -380,
            },
            1366: {
              slidesPerView: 2,
              spaceBetween: -400,
            },
          }}
        >
          {arr.map(function (element, index) {
            return (
              <SwiperSlide key={index}>
                <Card className="bg-transparent border-0">
                  <Card.Body className="bg-fazenda rounded border-0 rounded-3 text-white text-center">
                    <span>{element["texto"]}</span>
                  </Card.Body>
                  <Card.Footer className="bg-transparent text-center border-0">
                    <p className="cor-secondaria m-0 fw-bold">
                      {element["nome"]}
                    </p>
                    <p className="cor-fazenda m-0 fw-bold">
                      {element["local"]}
                    </p>
                  </Card.Footer>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </>
  );
}
