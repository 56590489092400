import { Container } from "react-bootstrap";
import "./style.css";
import React from "react";
import Fade from "react-reveal/Fade";
import BotaoFazenda from "../BotaoFazenda";
export default function Sobre({ children }) {
  return (
    <div id='A_Fazenda'>
      <div id="sobre">
        <Container>
          <div id="fundo_branco_texto">
            <div className="conteudo">{children}</div>
            <div className="sobre-a-fazenda row">
              <div className="col-xl-7 col-lg-9 col-12 content">
                <Fade left distance="30px">
                  <p className="fw-bold subtitulo h5 cor-secondaria">
                    FAZENDA SÃO JOAQUIM
                  </p>
                </Fade>

                <h1 className="fontAbhaya titulo-sobre fw-bold">
                  A felicidade tem o cheiro do campo e a beleza da fazenda!
                </h1>
                <div id="texto-sobre-a-fazenda">
                  <Fade left distance="30px">
                    <p>
                      <span>
                        A Fazenda São Joaquim reúne as vantagens do interior
                        paulista com a sua privilegiada localização para receber
                        grandes momentos.
                      </span>
                    </p>

                    <p>
                      <span>
                        As paisagens são inspiradoras, cenários perfeitos para o
                        seu ensaio fotográfico ser inesquecível!
                      </span>
                    </p>
                    <p>
                      <span>
                        Comporta diferentes opções de festas como, casamentos,
                        coquetéis, mini wedding, ensaios fotográficos,
                        batizados, festas de debutante, entre outros. Estamos
                        preparados para realizar seu evento do jeito que sempre
                        sonhou!
                      </span>
                    </p>
                    <p>
                      <span>
                        Faça-nos uma visita e encante-se com a beleza dos nossos
                        espaços naturais!
                      </span>
                    </p>
                  </Fade>
                </div>

                <div className="pt-4 pb-5">
                  <BotaoFazenda texto="CONHEÇA NOSSA HISTÓRIA" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
