import { Card, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import "./style.css";
import capela from "./img/CAPELA.webp";
import coqueteis from "./img/COQUETEIS.webp";
import ensaiosFotograficos from "./img/ENSAIOSFOTOGRAFICOS.webp";
import "swiper/css/effect-coverflow";

export default function Estrutura() {
  const arr = [
    { imagem: capela, texto: "CAPELA PARA CASAMENTOS" },
    { imagem: coqueteis, texto: "COQUETÉIS - MINI WEDDING" },
    { imagem: ensaiosFotograficos, texto: "ENSAIOS FOTOGRÁFICOS" },
  ];

  return (
    <>
      <Container id='acomodacoes'>
        <div className="row justify-content-center">
          <div className="col-12 col-xl-6">
            <div className="text-center pt-5 border-estrutura">
              <h6 className="cor-secondaria fw-bold mb-0">CONHEÇA NOSSA</h6>
              <h1 className="fontAbhaya cor-fazenda fw-bold">Estrutura</h1>
            </div>
            <div className="text-center py-4">
              <span>
                Com o intuito de proporcionar o melhor evento junto com a
                natureza, oferecemos diversos ambientes para seu evento.
                Ambientes ricos em detalhes rústicos e sofisticados ligados a
                beleza do campo, tornará tudo ainda mais mágico e inesquecível.
              </span>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="stage">
          <div className="swiper-container">
            <Swiper
              style={{
                "--swiper-navigation-color": "#5a3722",
                "--swiper-pagination-color": "#5a3722",
              }}
              loop
              loopedSlides={50}
              roundLengths={true}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={2}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 199,
                modifier: 3,
                slideShadows: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation, EffectCoverflow]}
              className="SlideFluid"
              slideToClickedSlide={true}
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                700: {
                  slidesPerView: 2,
                },
              }}
            >
              {arr.map(function (element, index) {
                return (
                  <SwiperSlide key={index}>
                    <Card className="mx-auto">
                      <Card.Img variant="top" src={element["imagem"]} />
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </Container>
    </>
  );
}
